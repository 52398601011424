/**
 * Slider
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 * @see https://github.com/wandoledzep/bxslider-4
 */

(function () {
	const slider = $('.slider > ul');
	if (slider.length > 0) {
		slider.bxSlider({
			controls: false,
			auto: true,
			autoHover: true,
			pause: 8000,
			touchEnabled: false
		});
	}
})();
