/**
 * Map
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

(function () {
	/* global google */
	if ($('#map').length > 0) {
		$('#map #static-map').remove();

		// Apply style attributes
		$('#map').css({
			width: $('#map').width(),
			height: $('#map').height()
		});

		/* Initialize the map */
		const map = new google.maps.Map(document.querySelector('#map'), {
			zoom: 16,
			center: new google.maps.LatLng(47.94057, 8.55115),
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			scrollwheel: false,
			disableDefaultUI: true
		});

		/* Set map styles */
		const mapStyles = new google.maps.StyledMapType([{
			featureType: 'road',
			elementType: 'geometry',
			stylers: [{
				visibility: 'simplified'
			}]
		}, {
			featureType: 'road.arterial',
			elementType: 'geometry',
			stylers: [{
				color: '#a19f87'
			}, {
				visibility: 'simplified'
			}]
		}, {
			featureType: 'water',
			elementType: 'geometry',
			stylers: [{
				color: '#0d9ba5'
			}, {
				visibility: 'simplified'
			}]
		}, {
			featureType: 'poi',
			stylers: [{
				visibility: 'off'
			}]
		}], {
			name: 'Ketterer Karte'
		});
		map.mapTypes.set('customMap', mapStyles);
		map.setMapTypeId('customMap');

		/* Parse hCard and position appropriate marker on the map */
		$('.adr').each(function () {
			const adr = {
				streetAddress: $(this).find('.street-address').text(),
				postalCode: $(this).find('.postal-code').text(),
				locality: $(this).find('.locality').text()
			};
			const geocoder = new google.maps.Geocoder();
			geocoder.geocode({
				address: `${adr.streetAddress}, ${adr.postalCode} ${adr.locality}`
			}, (results, status) => {
				if (status === google.maps.GeocoderStatus.OK) {
					const marker = new google.maps.Marker({
						position: results[0].geometry.location,
						clickable: false
					});
					marker.setMap(map);
				}
			});
		});
	}
})();
