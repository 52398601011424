/**
 * Form Element Cloner
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

(function () {
	const toggleRemovalButton = () => {
		$('#remove-clone').prop('disabled', $('.group li').length === 1);
	};

	$('#clone').on('click', () => {
		const $fields = $('.group li').last().clone();
		$fields.find('input, textarea').each(function () {
			const $label = $(this).prev('label');
			const $input = $(this);

			const numericalId = $('.group li').length + 1;
			const id = $input.attr('id').replace(/\d+$/, numericalId);

			$label.attr('for', id);
			$input.attr('id', id);
			$input.val('');
		});

		$fields.appendTo('.group ul');

		toggleRemovalButton();
	});

	$('#remove-clone').on('click', () => {
		$('.group li').last().remove();

		toggleRemovalButton();
	});
})();
