/**
 * Layout Adjustments
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

window.setTimeout(() => {
	const wrapper = $('.workflow .column-wrapper');
	if (wrapper.length > 0) {
		const columns = $(wrapper).find('.column');
		$.each(columns, (idx, column) => {
			const height = {
				wrapper: $(wrapper).height(),
				column: $(column).height()
			};
			if (height.wrapper > height.column) {
				const lastHeight = $(column).find('li:last').height();
				$(column).find('li:last').css({
					height: `${(height.wrapper - height.column) + lastHeight}px`
				});
			}
		});
	}
}, 250); // Timeout due to Webfonts
