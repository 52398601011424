/**
 * Lightbox
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

(function () {
	if ($('.lightbox').length > 0) {
		$('.lightbox a').colorbox({
			photo: true,
			rel: 'gallery',
			current: '{current} / {total}',
			opacity: 0.6
		});

		const id = $('.lightbox').attr('id');
		if (id !== '') {
			$(`a[href="#${id}"]`).each(function () {
				$(this).on('click', () => {
					$(`#${id} a:first`).click();
					return false;
				});
			});
		}
	}
})();
