/**
 * Lava Animation
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

window.setTimeout(() => {
	const navigation = $('nav ul');
	if (navigation.length > 0) {
		$(() => {
			navigation.on('mouseenter mouseleave focusin focusout', 'a', {
				lava: $('<div class="lava">').appendTo('nav')
			}, function (evt) {
				const elm = /(mouseleave|focusout)/.test(evt.type) ? $('nav .active')[0] : this;
				evt.data.lava.animate({
					width: elm ? elm.offsetWidth - 10 : 0,
					left: elm ? elm.offsetLeft + 5 : 0
				}, {
					duration: 200,
					easing: 'linear',
					queue: false
				});
			}).find('a:first').trigger('mouseleave');
		});
	}
}, 250); // Timeout due to Webfonts
