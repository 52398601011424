/**
 * Forms
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

(function () {
	/**
	 * Create and set URL slug.
	 * @private
	 */
	function createSlug() {
		const title = $('#title').val();
		if (title !== '') {
			const slug = $.trim(title).toLowerCase()
				.replace(/ä/g, 'ae')
				.replace(/ö/g, 'oe')
				.replace(/ü/g, 'ue')
				.replace(/ß/g, 'ss')
				.replace(/é/g, 'e')
				.replace(/è/g, 'e')
				.replace(/á/g, 'a')
				.replace(/à/g, 'a')
				.replace(/ñ/g, 'n')
				.replace(/€/g, 'euro')
				.replace(/\s\((?:[mwd]\/?){1,3}\)$/g, '') // Remove job gender from slug
				.replace(/\W/g, '-') // Replace all other characters with dash
				.replace(/_/g, '-')
				.replace(/--/g, '-') // Replace double dash
				.replace(/-$/g, '') // Replace final dash
				.slice(0, 99); // Keep the URL short (100 chars only)
			$('#slug').val(slug);
			if ($('#slug-output').length > 0) {
				const slugPrefix = $('#slug-output').attr('data-prefix');
				$('#slug-output').text(`${slugPrefix}${slug}`);
			}
		}
	}

	/**
	 * Parse YouTube or Vimeo embed code and set normal video URL.
	 * @private
	 */
	function parseEmbedCode() {
		const video = $('#video').val();
		if (video.indexOf('<') || video.indexOf('>')) {
			// Parse video ID from markup
			const vimeo = video.match(/vimeo\.com\/video\/(\d+)/);
			const youtube = video.match(/(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/);

			if (vimeo !== null) {
				$('#video').val(`https://vimeo.com/${vimeo[1]}`);
			}

			if (youtube !== null) {
				$('#video').val(`https://www.youtube.com/watch?v=${youtube[1]}`);
			}
		}
	}

	if ($('#slug').length > 0) {
		$('#title').on('keyup', createSlug);
		if ($('input[name=category]').length > 0) {
			$('input[name=category]').on('change', createSlug);
		}
	}

	if ($('#video').length > 0) {
		$('#video').on('input', parseEmbedCode);
	}

	if ($('.button-delete , .link-delete').length > 0) {
		$('.button-delete , .link-delete').on('click', () => window.confirm('Sind Sie sicher?')); /* eslint no-alert: "off" */
	}
})();
